<template>
  <div>
    <!--begin::customer-->
    <h3 class="card-label">
      {{ $t('purchases_refunds.purchases_refunds') }}
      <span class="text-muted pt-2 font-size-sm d-block"></span>
    </h3>


    <b-tabs content-class="mt-3" class="nav-custom-link">
      <b-tab :title="$t('basic_information')" @click="toggleTab('basic_information')">
        <div class="row">
          <div class="col-lg-6">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('purchases_refunds.supplier') }}<span class="text-danger">*</span></label>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <multiselect
                          v-model="supplier"
                          :placeholder="$t('purchases_refunds.supplier')"
                          :class="validation && validation.supplier_id ? 'is-invalid' : ''"
                          label="name"
                          track-by="id"
                          :options="suppliers"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          :disabled="true"
                          @search-change="getSuppliers($event)">
                      </multiselect>
<!--                      <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('purchases_invoices.supplier') }}.</span>-->
                      <span v-if="validation && validation.supplier_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.supplier_id[0] }}
                                    </span>
                    </div>
<!--                    <div class="col-sm-4">-->
<!--                      <button class="btn btn-primary" @click="showModal">{{ $t('add_new') }}</button>-->
<!--                    </div>-->
                  </div>

                </div>
              </div>
            </div>
            <b-card no-body class="mt-5">
              <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                  {{ $t('accounts') }}
                </b-button>
              </b-card-header>

              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body class="row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('accounts') }}</label>
                    <div class="input-group mb-3">
                      <multiselect
                          v-model="account"
                          :placeholder="$t('accounts')"
                          label="label"
                          track-by="id"
                          :options="accounts"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getAccounts($event)">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/accounting/chart-accounts/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('accounts') }}.</span>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="col-lg-6">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchases_refunds.purchase_refund_code') }}</label>
                  <input type="text" v-model="data.purchase_refund_code" disabled class="form-control" :class="validation && validation.purchase_refund_code ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.purchase_refund_code" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.purchase_refund_code[0] }}
                                </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchases_refunds.purchase_refund_date') }}</label>
                  <input type="date" v-model="data.purchase_refund_date" disabled class="form-control" :class="validation && validation.purchase_refund_date ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.purchase_refund_date" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.purchase_refund_date[0] }}
                                </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchases_refunds.currency') }}<span class="text-danger">*</span></label>
                  <div class="input-group mb-3">
                    <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                      <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                    </select>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.currency_id[0] }}
                                </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchases_refunds.inventory') }}<span class="text-danger">*</span></label>
                  <div class="input-group mb-3">
                    <multiselect v-model="inventory"
                                 :placeholder="$t('purchases_invoices.inventory')"
                                 label="name"
                                 track-by="id"
                                 :options="inventories"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span v-if="validation && validation.inventory_id" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.inventory_id[0] }}
                                </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('purchases_refunds.payment_method') }}</label>
                  <select v-model="data.payment_method" name="" id="payment_method" class="custom-select" :class=" validation && validation.payment_method ? 'is-invalid' : ''">
                    <option v-for="(row, index) in payment_method_list" :value="row.id" :key="index">{{ row.title }}</option>
                  </select>
                  <span v-if="validation && validation.payment_method" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.payment_method[0] }}
                                    </span>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('status') }}</label>
                  <select name="" id="f_status" v-model="data.status" type="text" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                    <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                  </select>
                  <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.status[0] }}
                                    </span>
                </div>
                <div class="col-lg-12 mb-5 pt-5">
                  <b-form-checkbox size="lg" v-model="data.total_amount_refunf_supplier" name="check-button" switch>{{ $t('purchases_refunds.total_amount_refunf_supplier') }}</b-form-checkbox>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('is_apply_taxes_invoice') }}:</label>
                  <b-form-checkbox size="lg" :disabled="true" v-model="data.is_apply_taxes_invoice" name="check-button" switch></b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row mt-5">
          <div class="col-md-12">
            <hr>
          </div>
          <div class="col-md-12">
            <div class="d-flex justify-content-between mt-2 p-2 bg-FFB803" style="height: 55px;">
              <h6 class="my-auto text-white">{{ $t('purchases_refunds.items') }}</h6>
              <!--                    <button type="button" class="btn btn-primary btn-sm" @click="addItemRowToList">{{ $t('add_more') }}</button>-->
            </div>
          </div>
          <div class="col-md-12">
            <div class="table-responsive bg-white pb-5">
<!--              <table class="table table-row-bordered table-custom-padding" @keyup.enter="addItemRowToList" @keyup.46="removeItemRowFromList">-->
              <table class="table table-row-bordered table-custom-padding">
                <thead>
                <tr>
                  <th width="250px">{{ $t('items.item_details') }}</th>
                  <th width="300px">
                    <div class="d-flex justify-content-between">
                      <span>{{ $t('purchases_refunds.item') }}</span>
                      <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal">
                        <b-icon icon="plus"></b-icon>
                      </a>
                    </div>
                  </th>
                  <!--                        <th>{{ $t('purchases_refunds.description') }}</th>-->
                  <th width="140px">{{ $t('purchases_refunds.unit_price') }}</th>
                  <th width="140px">{{ $t('purchases_refunds.original_qty') }}</th>
                  <th width="140px">{{ $t('purchases_refunds.unit') }}</th>
                  <th width="140px">{{ $t('purchases_refunds.qty') }}</th>
                  <th width="140px" v-if="isApplyTaxesInvoice">{{ $t('purchases_refunds.tax') }}</th>
                  <!-- <th>{{$t('purchases_refunds.tax_2')}}</th>-->
                  <!--                        <th>{{ $t('purchases_refunds.discount_type') }}</th>-->
                  <!--                        <th>{{ $t('purchases_refunds.discount_value') }}</th>-->
                  <th width="140px">{{ $t('discount') }}</th>
                  <th width="80px" v-if="isApplyTaxesInvoice">{{ $t('purchases_refunds.amount_tax') }}</th>
                  <th width="80px">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                  <th width="80px">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                  <th width="10px"></th>
                </tr>

                </thead>
                <tbody>
                <tr v-for="(row, index) in items_list" :key="index" :class="getClassValidationRepeaterByIndex(index)">
                  <td>
                    <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                      <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                      <div class="pl-2 pr-2 text-dark">
                        <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                        <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                        <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}: {{ row.item.sale_price }}</p>
                        <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}: {{ row.item.purchase_price }}</p>
                        <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ row.item.sum_available_qty }}</p>
                      </div>
                    </a>
                  </td>
                  <td>
                    <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('purchases_refunds.item') ">
                      <select name="" id="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                        <option value="name">{{ $t('items.name') }}</option>
                        <option value="sku_code">{{ $t('items.sku_code') }}</option>
                      </select>
                      <multiselect v-model="row.item"
                                   :placeholder="$t('purchases_refunds.item')"
                                   :label="row.f_by? row.f_by:'name'"
                                   track-by="id"
                                   :options="items"
                                   :multiple="false"
                                   @input="selectItem(index)"
                                   :disabled="isDisabled"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :internal-search="false"
                                   @search-change="getItems(row.f_by, $event)">
                      </multiselect>
                    </div>
                    <input v-model="row.description" type="text" class="form-control mt-3" :placeholder="$t('purchases_refunds.description') ">
                  </td>
                  <td><input v-model="row.unit_price" disabled @input="setValue(index)" type="number" class="form-control"></td>
                  <td><input v-model="row.original_qty" disabled type="number" class="form-control"></td>
                  <td>
                    <multiselect v-model="row.unit" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('credits.unit')"
                                 :placeholder="$t('credits.unit')"
                                 label="name"
                                 track-by="id"
                                 :options="(row.item && row.item.units) ? row.item.units:[]"
                                 :multiple="false"
                                 :disabled="true"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                    <span v-if="validation && validation[`items_list.${index}.unit`]" class="fv-plugins-message-container invalid-feedback">
                            {{ validation[`items_list.${index}.unit`][0] }}
                          </span>

                    <input v-model="row.units_number" @input="setValue(index)" v-if="row.item && row.item.unit && row.unit && row.item.unit.id != row.unit.id" min="0" type="number" class="form-control mt-3">

                  </td>
                  <td>
                    <input v-model="row.qty" @input="setValue(index)" :disabled="row.item && row.item.unit && row.unit && row.item.unit.id != row.unit.id" type="number" class="form-control">
                    <span v-if="validation && validation[`items_list.${index}.qty`]" class="fv-plugins-message-container invalid-feedback">
                        {{ validation[`items_list.${index}.qty`][0] }}
                      </span>
                  </td>
<!--                  <td>-->
<!--                    -->
<!--                    <input v-model="row.tax_percentage" min="0" :disabled="!(row.item && row.item.taxes  && row.item.taxes.length > 0)" @input="setValue(index)" type="number" class="form-control">-->
<!--                  </td>-->
                  <td  v-if="isApplyTaxesInvoice">
                    <input v-for="(row_tax, index_tax) in row.tax_applied" v-model="row_tax.percentage" :key="'index_tax'+index_tax" min="0" :disabled="true" @input="setValue(index)" type="number" class="form-control" style="margin-bottom: 5px;">
                  </td>
                  <td>
                    <div class="d-flex">
                      <select name="" id="discount_type" @change="setValue(index)" v-model="row.discount_type" class="custom-select">
                        <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                      </select>
                      <input v-model="row.discount_value" @input="setValue(index)" type="number" class="form-control">
                    </div>
                  </td>

                  <td v-if="isApplyTaxesInvoice">{{ row.amount_tax }}</td>
                  <td>{{ row.subtotal_before_discount }}</td>
                  <td>{{ row.subtotal_after_discount }}</td>
                  <td>
                    <v-icon style="color: #dc3545;" class="m-40-4" small v-if="items_list.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td  :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.total_amount_before_discount') }}</th>
                  <th colspan="2"><span>{{ data.total_amount_before_discount ? data.total_amount_before_discount : '0' }} {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                <tr>
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('sales_invoices.total_amount_after_discount') }}</th>
                  <th colspan="2"><span>{{ data.total_amount_after_discount ? data.total_amount_after_discount: '0' }} {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                <tr>
                  <td class="border-0" :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('purchases_invoices.invoice_total_discount') }}</th>
                  <th colspan="2"><span>{{ data.invoice_total_discount ? data.invoice_total_discount: '0' }} {{currency_code}}</span></th>
                  <th></th>
                </tr>
                <tr>
                  <td class="border-0"  :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('purchases_invoices.invoice_total_discount') }}</th>
                  <th colspan="2"><span>{{ total_discount ? total_discount : '0' }} {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                <tr v-if="isApplyTaxesInvoice">
                  <td class="border-0" colspan="6"></td>
                  <th colspan="2">{{ $t('purchases_invoices.total_tax') }}</th>
                  <th colspan="2"><span>{{ total_tax ? total_tax : '0' }} {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                <tr>
                  <td class="border-0"  :colspan="isApplyTaxesInvoice ? '6' : '4'"></td>
                  <th colspan="2">{{ $t('purchases_invoices.total') }}</th>
                  <th colspan="2"><span>{{ data.invoice_total ? data.invoice_total : '0' }} {{ currency_code }}</span></th>
                  <th></th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <b-tabs content-class="mt-3" class="nav-custom-link">
            <b-tab :title="$t('discount_and_settlement')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('purchases_refunds.discount_type') }}</label>
                    <select name="" id="discount_type" v-model="data.discount_type" class="custom-select" :class="validation && validation.discount_type ? 'is-invalid' : ''">
                      <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                    </select>
                    <span v-if="validation && validation.discount_type" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.discount_type[0] }}
                                </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('purchases_refunds.discount_value') }}</label>
                    <input type="number" v-model="data.discount_val" class="form-control" :class="validation && validation.discount_val ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.discount_val" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.discount_val[0] }}
                                </span>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('delivery_information')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('purchases_refunds.shipping_fees') }}</label>
                    <input type="number" v-model="data.shipping_fees" class="form-control" :class="validation && validation.shipping_fees ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.shipping_fees" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.shipping_fees[0] }}
                                </span>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('deposit')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('purchases_refunds.deposit_type') }}</label>
                    <select name="" id="deposit_type" v-model="data.deposit_type" class="custom-select" :class="validation && validation.deposit_type ? 'is-invalid' : ''">
                      <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                    </select>
                    <span v-if="validation && validation.deposit_type" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.deposit_type[0] }}
                                </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('purchases_refunds.deposit_value') }}</label>
                    <input type="number" v-model="data.deposit_val" class="form-control" :class="validation && validation.deposit_val ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.deposit_val" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.deposit_val[0] }}
                                </span>
                  </div>

                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('notes')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('purchases_refunds.attachment') }}</label>
                    <div class="form-group">
                      <upload-file
                          @file="listenerAttachment"
                          :inner-id="'attachment'"
                          :placeholder="$t('purchases_refunds.attachment')"
                          :upload="dir_upload"
                          :start-link="'base'"
                          v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
                      </upload-file>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('purchases_refunds.notes') }}</label>
                    <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                    <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.notes[0] }}
                                </span>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>


        </div>
      </b-tab>

      <b-tab v-if="idEditing" :title="$t('activity_log.activity_log')" @click="toggleTab('activity_log')">
        <div class="card card-custom mt-5">
          <div class="card-body">
            <br>
            <activity-log :inner-key="key" :id="idEditing"></activity-log>
          </div>
        </div>
      </b-tab>
      <b-tab v-if="idEditing" :title="$t('stock_change_control_log')" @click="toggleTab('statistics_log_inventory')">
        <div class="card card-custom mt-5">
          <div class="card-body">
            <br>
            <statistics-log-inventory :inner-key="key_statistics" :id="idEditing"></statistics-log-inventory>
          </div>
        </div>
      </b-tab>

    </b-tabs>


    <div class="pl-0 pr-0" v-if="tab_name != 'activity_log' && tab_name != 'statistics_log_inventory'">
      <div class="row">
        <div class="col-lg-6 mt-10">
          <button type="reset" class="btn btn-primary mr-2" @click="save($event)">{{ $t('save') }}</button>
          <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>


    <!--end::customer-->
    <b-modal ref="modal" size="lg" hide-footer :title="$t('suppliers.add_new_supplier')">
      <supplier-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewSupplier"></supplier-form>
    </b-modal>
    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import SupplierForm from "@/view/content/forms/SupplierForm";
import ItemForm from "@/view/content/forms/ItemForm";
import {mapGetters, mapState} from "vuex";
import ActivityLog from "@/view/content/widgets/details/ActivityLog";
import StatisticsLogInventory from "@/view/content/widgets/details/StatisticsLogInventory";
import dataTypeNumbers from '@/core/config/mix/dataTypeNumbers';
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "form-purchases-refunds",
  components: {'supplier-form': SupplierForm, 'item-form': ItemForm, 'activity-log': ActivityLog, 'statistics-log-inventory': StatisticsLogInventory},
  data() {
    return {
      mainRoute: 'purchases/purchases-refunds',
      mainRouteDependency: 'base/dependency',
      dir_upload: 'purchases',
      mainRoutePurchaseInvoice: 'purchases/purchases-invoices',

      data: {
        // title: "",
        supplier_id: "",

        currency_id: "",
        shipping_fees: "",
        // branch_id: "",
        inventory_id: "",
        attachment: "",
        notes: "",
        purchase_refund_code: "",
        purchase_refund_date: "",
        discount_type: 1,
        discount_val: "",
        // payment_type: "",
        // payment_ref: "",
        status: 1,
        deposit_type: 1,
        deposit_val: null,
        total_amount_refunf_supplier: true,
        account_id: "",
        purchase_invoice_id: null,
        // exchange_rate: null,
        invoice_total: 0,
        payment_method: 0,
        is_apply_taxes_invoice: false,
        total_amount_before_discount: 0,
        total_amount_after_discount: 0,
        total_out_tax: 0,
        total_tax_type_val: [],
      },
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      reloadUploadAttachment: true,
      attachment_url: '',

      suppliers: [],
      currencies: [],
      inventories: [],
      supplier: null,
      // branch: null,
      inventory: null,
      items: [],
      taxes: [],
      // branches: [],
      status_list: [],
      accounts: [],
      account: [],
      payment_method_list: [],
      isDisabled: true,
      purchase_invoice_id: this.$route.params.purchase_invoice_id ? this.$route.params.purchase_invoice_id : null,

      // payment_types: [],
      discount_types: [
        {id: 1, name: '-'},
        {id: 2, name: '%'},
        {id: 3, name: this.$t('discount_types.value')},
      ],

      validation: null,
      tax_default: null,
      items_list_form: {id: null, item: null, description: null, unit_price: null, original_qty: null, qty: null, tax: null, tax_2: null, subtotal: null, discount_type: null, discount_value: null, subtotal_before_tax: null, amount_tax: null, tax_percentage: null, subtotal_before_discount: null, subtotal_after_discount: null, subtotal_after_tax: null, units_number: null, unit: null, amount_excluded_tax: 0},
      items_list: [],
      items_total: 0,
      total_tax: 0,
      total: 0,
      total_discount: 0,
      tab_name: 'basic_information',
      key: 'PurchaseInvoiceRefund',
      key_statistics: 'PurchaseRefund',
      currency_code: null,
      repeater_validation: [],
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),
    isApplyTaxesInvoice(){
      let _status = this.data.is_apply_taxes_invoice;
      return _status;
    },
  },
  watch: {

    account: function (val) {
      if (val) {
        this.data.account_id = val.id;
      }
    },
    supplier: function (val) {
      if (val) {
        this.data.supplier_id = val.id;
      }
    },
    inventory: function (val) {
      if (val) {
        this.data.inventory_id = val.id;
      }
    },
    // branch: function (val) {
    //   if (val) {
    //     this.data.branch_id = val.id;
    //   } else {
    //     this.data.branch_id = null;
    //   }
    // },
    items_list: {
      handler(val) {
        // if (val.length > 0) {
        //   this.items_total = this.$_.sumBy(this.items_list, (row) => {
        //     return row.subtotal ? parseFloat(row.subtotal) : 0;
        //   });
        //     this.items_total = this.items_total ? parseFloat(this.items_total) : 0;
        //     this.total_tax = this.$_.sumBy(this.items_list, (row) => {
        //         return row.tax ? parseFloat(row.tax.value_rate) : 0;
        //     });
        //     this.total_tax = this.total_tax ? this.total_tax : 0;
        //     this.total = this.items_total + this.total_tax;
        //     this.data.invoice_total = this.total;
        // }

        if (val.length > 0) {
          this.items_total = this.$_.sumBy(this.items_list, (row) => {
            return row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
          });
          /**
           * sum amount before discount
           */
          this.data.total_amount_before_discount = this.$_.sumBy(this.items_list, (row) => {
            return (row.subtotal_before_discount ? parseFloat(row.subtotal_before_discount) : 0);
          });
          /**
           * sum amount after discount
           */
          this.data.total_amount_after_discount = this.$_.sumBy(this.items_list, (row) => {

            return (row.subtotal_after_discount ? parseFloat(row.subtotal_after_discount) : 0);
          });

          this.items_total = this.items_total ? parseFloat(this.items_total) : 0;
          this.total_tax = this.$_.sumBy(this.items_list, (row) => {
            return row.amount_tax ? parseFloat(row.amount_tax) : 0;
          });
          let _amount_excluded_tax = this.$_.sumBy(this.items_list, (row) => {
            return row.amount_excluded_tax ? parseFloat(row.amount_excluded_tax) : 0;
          });
          this.total_discount = this.$_.sumBy(this.items_list, (row) => {
            if (!row.discount_type || row.discount_type == 1) {
              return 0;
            }
            let _subtotal = ((row.unit_price ? row.unit_price : 0) * (row.qty ? row.qty : 0));
            // let _subtotal = row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0;
            return ((row.discount_type == 2 && row.discount_value) ? (parseFloat(row.discount_value) / 100) * _subtotal : (row.discount_value ? parseFloat(row.discount_value) : 0));
          });
          this.total_tax = this.total_tax ? parseFloat(this.total_tax) : 0;

          this.data.total_out_tax = this.$_.sumBy(this.items_list, (row) => {
            if(!row.tax_applied || row.tax_applied.length == 0){
              if (row.unit_price && row.qty){
                return parseFloat(row.unit_price) *  parseFloat(row.qty);
              }
            }
            return 0;
          });
          this.setTotalTaxTypeVal();
          this.data.total_out_tax = this.data.total_out_tax ? this.data.total_out_tax.toFixed(2) : 0;

          this.total = this.$_.sumBy(this.items_list, (row) => {
            return row.subtotal ? parseFloat(row.subtotal) : 0;
          });

          // this.data.invoice_subtotal = this.items_total;

          this.data.invoice_total = (this.data.total_amount_after_discount  + _amount_excluded_tax);
          this.data.invoice_total = this.parseToShortFloat(this.data.invoice_total);
          // this.data.invoice_total_taxes = this.total_tax;
        }
      },
      deep: true
    },
    "data.currency_id": function (val) {
      if (val) {
        let _currency = this.currencies.find((row) => row.id == val);
        if (_currency) {
          this.currency_code = _currency.code;
        } else {
          this.currency_code = null;
        }
      } else {
        this.currency_code = null;
      }

      // if (val) {
      //   this.getExchangeRate(val);
      // } else {
      //   this.data.exchange_rate = null;
      // }
    },
  },
  methods: {
    ...dataTypeNumbers,
    ...timeZoneStructure,
    toggleTab(tab_name) {
      this.tab_name = tab_name
    },

    validationQTYChecking(_status = true) {
      let _main_validation_status = false;
      this.validation = [];
      this.items_list.forEach((row, index) => {
        let _validation_status = false;

        if (parseFloat(row.qty) > parseFloat(row.original_qty)) {
          this.validation[`items_list.${index}.qty`] = [];
          this.validation[`items_list.${index}.qty`][0] = this.$t('the_quantity_field_must_be_less_or_equal_remaining_quantity');
          _validation_status = true;
        }

        if(!row.unit && _status){
          this.validation[`items_list.${index}.unit`] = [];
          this.validation[`items_list.${index}.unit`][0] = this.$t('the_unit_field_must_be_required');
          _validation_status = true;
        }

        // if (parseFloat(row.amount_tax) <= 0 && row.tax_applied.length > 0) {
        //   _validation_status = true;
        // }else if(!row.amount_tax && _status && row.tax_applied.length > 0){
        //   _validation_status = true;
        // }
        //
        // if (parseFloat(row.subtotal_before_discount) <= 0) {
        //   _validation_status = true;
        // }else if(!row.subtotal_before_discount && _status){
        //   _validation_status = true;
        // }


        if (_validation_status && _status){
          let _index = this.repeater_validation.indexOf(index);
          if (_index < 0) {
            this.repeater_validation.push(index);
          }
        }else{
          let _index = this.repeater_validation.indexOf(index);
          if (_index > -1) {
            this.repeater_validation.splice(_index, 1);
          }
        }

      });
      if (this.repeater_validation.length > 0){
        _main_validation_status = true;
      }
      if (_main_validation_status){
        let _items = [...this.items_list];
        this.items_list = [];
        this.items_list = _items;
        if (_status)
          this.$errorAlertMessage(this.$t('unable_to_send_due_to_data_entry_error'));
      }
      return _main_validation_status;

    },

    save(event) {
      if (this.validationQTYChecking()) {
        event.preventDefault();
        return false;
      } else {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
      }
    },

    create() {
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => parseFloat(row.qty) > 0);

      ApiService.post(`${this.mainRoute}`, {
        items_list: _items_list,
        items_total: this.items_total,
        total_taxes: this.total_tax,
        invoice_total: this.total,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'purchases-refunds.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => parseFloat(row.qty) > 0);

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        items_list: _items_list,
        items_total: this.items_total,
        total_taxes: this.total_tax,
        // total: this.total,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'purchases-refunds.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    getClassValidationRepeaterByIndex(index) {
      if (index != null) {
        if (this.repeater_validation.includes(index))
            // return 'tr-validation-error';
          return 'border-validation';
      }
      return '';
    },

    async getData() {
      this.reloadUploadAttachment = false;
      await ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        // this.data.title = response.data.data.title;
        this.data.supplier_id = response.data.data.supplier_id;
        this.supplier = response.data.data.supplier;
        this.inventory = response.data.data.inventory;
        this.data.purchase_refund_code = response.data.data.purchase_refund_code;
        this.data.purchase_refund_date = response.data.data.purchase_refund_date;
        this.data.discount_type = response.data.data.discount_type;
        this.data.discount_val = response.data.data.discount_val;
        this.data.total_out_tax = response.data.data.total_out_tax ? response.data.data.total_out_tax : 0;
        this.data.total_tax_type_val = response.data.data.total_tax_type_val ? response.data.data.total_tax_type_val : [];
        // this.data.payment_type = response.data.data.payment_type;
        // this.data.payment_ref = response.data.data.payment_ref;
        this.data.currency_id = response.data.data.currency_id;
        this.data.shipping_fees = response.data.data.shipping_fees;
        this.data.inventory_id = response.data.data.inventory_id;
        this.data.attachment = response.data.data.attachment;
        this.data.notes = response.data.data.notes;
        this.data.status = response.data.data.status;
        this.attachment_url = response.data.data.attachment_url;
        this.items_list = response.data.data.items_list;
        // this.data.branch_id = response.data.data.branch_id;
        // this.branch = response.data.data.branch;
        this.data.deposit_type = response.data.data.deposit_type;
        this.data.deposit_val = response.data.data.deposit_val;
        this.data.account_id = response.data.data.account_id;
        this.data.total_amount_refunf_supplier = response.data.data.total_amount_refunf_supplier;
        // this.data.exchange_rate = response.data.data.exchange_rate;
        this.data.purchase_invoice_id = response.data.data.purchase_invoice_id;
        this.data.payment_method = response.data.data.payment_method;
        this.data.is_apply_taxes_invoice = response.data.data.is_apply_taxes_invoice;
        this.account = response.data.data.account;
        if (!response.data.data.account_id) {
          this.getDefaultAccount();
        }

        // if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
        //   this.addItemRowToList();
        // }
        this.items_total = response.data.data.items_total;
        this.total_tax = response.data.data.total_taxes;
        this.total = response.data.data.total;
        this.data.invoice_total = response.data.data.invoice_total;
        this.data.total_amount_before_discount = response.data.data.total_amount_before_discount;
        this.data.total_amount_after_discount = response.data.data.total_amount_after_discount;
        this.reloadUploadAttachment = true;
      });
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },

    getDefaultCurrency() {
      ApiService.get(this.mainRouteDependency + "/default-currency").then((response) => {
        this.data.currency_id = response.data.data.id;
      });
    },

    getSuppliers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;

      });
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter, 'item_type': 1}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getTaxes(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/taxes?type=3`, {params: {filter: filter}}).then((response) => {
          this.taxes = response.data.data;
        });
      } else {
        this.taxes = [];
      }
    },
    // async getDefaultTax() {
    //   await ApiService.get(`${this.mainRouteDependency}/default-tax`).then((response) => {
    //     this.tax_default = response.data.data;
    //   });
    // },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;

      });
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/status", {params:{type: 'purchases_refund'}}).then((response) => {
        this.status_list = response.data.data;
      });
    },
    // getPaymentsTypes() {
    //     ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
    //         this.payment_types = response.data.data;
    //     });
    // },

    loadOptions() {
    },


    addItemRowToList() {
      // this.items_list_form.tax = this.tax_default;
      // this.items_list_form.tax_2 = this.tax_default;
      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {id: null, item: null, description: null, unit_price: null, original_qty: null, qty: null, tax: null, tax_2: null, subtotal: null, discount_type: null, discount_value: null, subtotal_before_tax: null, amount_tax: null, tax_percentage: null, units_number: null, unit: null, amount_excluded_tax: 0};
    },
    removeItemRowFromList(index = 0) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
      }
    },
    listenerAttachment(event) {
      if (event) {
        this.attachment_url = event.pathDB;
        this.data.attachment = event.name;
      }
    },
    selectItem(index) {
      this.items_list[index].unit_price = this.items_list[index].item.purchase_price;
      this.items_list[index].tax_percentage = this.items_list[index].item ? this.items_list[index].item.total_taxes : 0;
      this.setValue(index);
    },
    setValue(index) {
      // this.items_list[index].subtotal = (this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0);

      this.zeroForNegative();

      if (this.items_list[index].item && this.items_list[index].unit && this.items_list[index].item.unit) {
        if (this.items_list[index].item.unit.id != this.items_list[index].unit.id) {
          if (this.items_list[index].item.is_min) {
            this.items_list[index].qty = ((this.items_list[index].unit.number ? parseFloat(this.items_list[index].unit.number) : 0) * (this.items_list[index].units_number ? parseFloat(this.items_list[index].units_number) : 0)).toFixed(3);
          } else {
            this.items_list[index].qty = ((this.items_list[index].units_number ? parseFloat(this.items_list[index].units_number) : 0) / (this.items_list[index].unit.number ? parseFloat(this.items_list[index].unit.number) : 0)).toFixed(3);
          }
        } else {
          this.items_list[index].units_number = 0;
          // this.items_list[index].qty = 0;
        }
      }

      let promise = this.getItemTax(index);
      Promise.all([promise]).then(()=>{
        let _subtotal = ((this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0));
        this.items_list[index].subtotal_before_tax = (_subtotal).toFixed(2);
        let _discount = 0;
        if (this.items_list[index].discount_type == 1 || !this.items_list[index].discount_type) {
          _discount = 0;
        } else {
          _discount = ((this.items_list[index].discount_type == 2 && this.items_list[index].discount_value) ? (parseFloat(this.items_list[index].discount_value) / 100) * _subtotal : (this.items_list[index].discount_value ? parseFloat(this.items_list[index].discount_value) : 0));
        }
        // _subtotal = _subtotal - _discount;
        // let _tax = _subtotal * (this.items_list[index].tax_percentage ? parseFloat(this.items_list[index].tax_percentage) / 100 : 0);

        // this.items_list[index].subtotal_before_tax = (_subtotal).toFixed(2);
        // this.items_list[index].amount_tax = (_tax).toFixed(2);
        this.items_list[index].subtotal_before_discount = _subtotal;

        this.items_list[index].subtotal_after_discount = _subtotal - _discount;

        this.items_list[index].subtotal_before_discount = parseFloat(this.items_list[index].subtotal_before_discount).toFixed(2);
        this.items_list[index].subtotal_after_discount = parseFloat(this.items_list[index].subtotal_after_discount).toFixed(2);
        this.items_list[index].subtotal = parseFloat(this.items_list[index].subtotal_before_tax).toFixed(2);

      })

    },
    // zeroForNegative(){
    //   this.items_list = this.items_list.map((row)=>{
    //     if (row.unit_price < 0){
    //       row.unit_price = 0;
    //     }
    //     if (row.qty < 0){
    //       row.qty = 0;
    //     }
    //     if (parseInt(row.qty) > parseInt(row.original_qty)){
    //       row.qty = row.original_qty;
    //     }
    //     if (row.discount_value < 0){
    //       row.discount_value = 0;
    //     }
    //     return row;
    //
    //   });
    // },
    zeroForNegative() {
      this.items_list = this.items_list.map((row) => {
        if(String(row.unit_price).length > 12){
          row.unit_price = String(row.unit_price).slice(0, 12);
        }
        if(String(row.qty).length > 9){
          row.qty = String(row.qty).slice(0, 9);
        }

        if (isNaN(row.unit_price) ) {
          row.unit_price = 1;
        } else if (parseFloat(row.unit_price) < 0) {
          row.unit_price = 0;
        }

        if (isNaN(row.qty)) {
          row.qty = 0;
        } else if (parseFloat(row.qty) < 0) {
          row.qty = 0;
        }

        // else if (parseFloat(row.qty) > parseFloat(row.original_qty)) {
        //   row.qty = row.original_qty;
        // }

        if (isNaN(row.units_number)) {
          row.units_number = 0;
        } else if (parseFloat(row.units_number) < 0) {
          row.units_number = 0;
        }

        if (isNaN(row.discount_value)) {
          row.discount_value = 0;
        }else if (parseFloat(row.discount_value) < 0) {
          row.discount_value = 0;
        }

        if(row.discount_type == 1){
          row.discount_value = 0;
        }else if (row.discount_type == 2){
          if (parseFloat(row.discount_value) > 100) {
            row.discount_value = 100;
          }
        }else if (row.discount_type == 3){
          if (parseFloat(row.discount_value) > parseFloat(row.subtotal_before_discount)) {
            row.discount_value = row.subtotal_before_discount;
          }
        }


        if (isNaN(row.tax_percentage) || !Number(row.tax_percentage)) {
          row.tax_percentage = 0;
        }
        if (parseFloat(row.tax_percentage) < 0) {
          row.tax_percentage = 0;
        }
        return row;

      });
    },


    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },
    getDataAfterCreateNewSupplier(object) {
      let promise = this.getSuppliers();
      Promise.all([promise]).then(() => {
        this.data.supplier_id = object.id;
      })
    },
    getDataAfterCreateNewItem(object) {
      let promise = this.getItems();
      Promise.all([promise]).then(() => {
        if (object) {
          this.items_list[0].item = object;
          this.items_list[0].unit_price = object.purchase_price;
          this.items_list[0].tax_percentage = object.total_taxes ? object.total_taxes : 0;
          // this.items_list[0].tax = (object.taxes && object.taxes[0] && object.taxes[0].tax) ? object.taxes[0].tax : null;
        }
      })
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/5").then((response) => {
        this.data.purchase_refund_code = response.data.data.code;
      });
    },

    // getAccounts() {
    //     ApiService.get(this.mainRouteDependency + `/account_parents`, {params: {checkDisabled: 1, key: 'return_purchase'}}).then((response) => {
    //         this.accounts = response.data.data;
    //     });
    // },
    getAccounts(filter) {
      if (filter && filter.length >= 3)
        return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
          this.accounts = response.data.data;
        });
    },
    getDefaultAccount() {
      ApiService.get(`${this.mainRouteDependency}/get_default_account/return_purchase`).then((response) => {
        this.account = response.data.data;
      });
    },
    updateValue(value) {
      this.data.account_id = value;
    },
    // getExchangeRate(id) {
    //   ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
    //     this.data.exchange_rate = response.data.data.initial_val;
    //   });
    // },
    getPaymentMethodList() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_method_list = response.data.data;
      });
    },
    async getPurchaseInvoiceData() {
      if (this.data.purchase_invoice_id) {
        await ApiService.get(`${this.mainRoutePurchaseInvoice}/${this.data.purchase_invoice_id}`).then((response) => {

          this.data.supplier_id = response.data.data.supplier_id;
          this.supplier = response.data.data.supplier;
          this.data.currency_id = response.data.data.currency_id;
          // this.data.branch_id = response.data.data.branch_id;
          // this.branch = response.data.data.branch;
          this.data.inventory_id = response.data.data.inventory_id;
          this.data.shipping_fees = response.data.data.shipping_fees;
          this.data.is_apply_taxes_invoice = response.data.data.is_apply_taxes_invoice;


          this.inventory = response.data.data.inventory;
          this.items_list = response.data.data.items_list.map((row) => {
            row.original_qty = row.received_quantity;
            // row.qty = row.received_quantity;
            row.qty = 0;
            row.discount_type = 1;
            row.discount_val = 0;
            row.discount_value = 0;
            row.units_number = 0;
            row.data_validation = [];
            row.subtotal_before_discount = 0;
            row.subtotal_after_discount = 0;
            row.subtotal_before_tax = 0;
            row.amount_tax = 0;
            row.amount_excluded_tax = 0;

            return row;
          });



          if (this.data.invoice_id && response.data.data.supplier_id) {
            let _supplier = this.suppliers.find(row => row.id == response.data.data.supplier_id);
            if (_supplier) {
              this.customer = _supplier;
            }
            this.isDisabled = true;
          }


            this.items_list.forEach((row, index) => {
              this.setValue(index);
            });



        });
      }
    },

    defaultDataForUser(){
      ApiService.get(this.mainRouteDependency +"/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;

        this.data.branch_id = response.data.data.branch_id;
        this.branch = response.data.data.branch;
        this.data.inventory_id = response.data.data.inventory_id;
        this.inventory = response.data.data.inventory;
        // this.items_list_form.tax = this.user_personal_info.tax;
      });
    },
    async getItemTax(index){
      // if(this.isApplyTaxesInvoice) {
        let item_id = this.items_list[index].item ? this.items_list[index].item.id : null;
        let quantity = this.items_list[index].qty ? this.items_list[index].qty : 0;
        // let unit_price = this.items_list[index].unit_price ? this.items_list[index].unit_price : null;
        let discount = this.items_list[index].discount_value ? this.items_list[index].discount_value : null;
        let discount_type = this.items_list[index].discount_type ? this.items_list[index].discount_type : null;

        if (item_id && parseFloat(quantity) ){
          await ApiService.get(`/v2/items/purchase/${this.data.purchase_invoice_id}/refund/calculate-subtotal`,
              {
                params: {
                  item_id: item_id, quantity: quantity,
                  // sale_price: unit_price,
                  discount: discount, discount_type: discount_type, row_index: index
                }
              }).then((response) => {
            this.items_list[response.data.data.row_index].amount_tax = response.data.data.tax_total;
            this.items_list[response.data.data.row_index].tax_applied = response.data.data.applied_taxes;
            this.items_list[response.data.data.row_index].amount_excluded_tax = this.parseToShortFloat(response.data.data.excluded_tax_per_item);

            // this.items_list[response.data.data.row_index].amount_excluded_tax = response.data.data.excluded_taxes_total;
            this.items_list[response.data.data.row_index].unit_price = response.data.data.unit_price ? response.data.data.unit_price : 0;
            // this.items_list[response.data.data.row_index].subtotal_before_discount = (response.data.data.unit_price ?  response.data.data.unit_price : 0) * (this.items_list[response.data.data.row_index].quantity ? parseFloat(this.items_list[response.data.data.row_index].quantity): 0)
            // this.calcItemListForTotal();

            this.items_list[response.data.data.row_index].data_validation = {item_id: item_id , tax_total: response.data.data.tax_total, subtotal_before_tax: response.data.data.sub_total};

            // this.calcItemListForTotal();
          });
        }else{
          this.items_list[index].amount_tax = 0;
          this.items_list[index].amount_excluded_tax = 0;

          this.items_list[index].data_validation = {item_id: item_id , tax_total: 0, subtotal_before_tax: this.items_list[index].subtotal_before_tax};

          // this.calcItemListForTotal();
        }

      // }
    },
    setTotalTaxTypeVal(){
      this.data.total_tax_type_val = [];
      this.items_list.forEach((row)=>{
        if(row.tax_applied && row.tax_applied.length > 0){
          row.tax_applied.forEach((_row)=>{
            let index_tax = this.data.total_tax_type_val.findIndex(it => _row.tax_id == it.tax_id);
            if (index_tax >= 0){
              this.data.total_tax_type_val[index_tax].tax_value =  this.parseToShortFloat((this.data.total_tax_type_val[index_tax].tax_value ? parseFloat(this.data.total_tax_type_val[index_tax].tax_value) : 0)+ (row.tax_value ? parseFloat(row.tax_value) : 0));
              this.data.total_tax_type_val[index_tax].amount_applied =  this.parseToShortFloat((this.data.total_tax_type_val[index_tax].amount_applied ? parseFloat(this.data.total_tax_type_val[index_tax].amount_applied) : 0)+ (row.subtotal_before_tax ? parseFloat(row.subtotal_before_tax) : 0));
            }else{
              this.data.total_tax_type_val.push({tax_id: _row.tax_id, tax_value: this.parseToShortFloat(_row.tax_value), amount_applied: this.parseToShortFloat(row.subtotal_before_tax)});
            }
          });

        }
      });
    },
  },

  mounted() {
    if (this.purchase_invoice_id == null && this.idEditing == null) {
      this.$router.push({name: 'purchases-refunds.index'});
    }

    // this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.purchases_refunds")}]);
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.purchases_refunds"), route: '/purchases/purchases-refunds'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


    this.getInventories();
    this.getCurrencies();

    this.getItems();

    this.getStatusList();
    this.getAccounts();
    this.getPaymentMethodList();

    if (this.idEditing) {
      let promise = this.getData();
      Promise.all([promise]).then(() => {
        if (!this.data.account_id) {
          this.getDefaultAccount();
        }
      });
    } else {
      this.defaultDataForUser();
      this.data.purchase_invoice_id = this.purchase_invoice_id;
      let promise = this.getPurchaseInvoiceData();

      // Promise.all([promise]).then(() => {
      //   if (this.items_list.length <= 0) {
      //     this.addItemRowToList();
      //   }
      // });

      this.getCode();
      if (!this.data.account_id) {
        this.getDefaultAccount();
      }
      this.getTimeZone().then((res)=>{
        this.data.purchase_refund_date = res;
      });
      // this.data.purchase_refund_date = new Date().toISOString().slice(0, 10);
    }

  },
};
</script>